<template>
    <div class="EmptyProductList">
        <i18n-t
            v-if="hasLink.value && getMessageKey !== ''"
            :keypath="getMessageKey"
            class="EmptyProductList-message"
            tag="p"
        >
            <a
                class="EmptyProductList-messageLink"
                :href="getLink"
            >
                {{ getLinkText }}
            </a>
        </i18n-t>
        <p
            v-else
            class="EmptyProductList-message"
        >
            {{ $t(getMessageKey) }}
        </p>
        <div class="EmptyProductList-illustWrapper">
            <img
                :src="getAnimationBackground"
                class="EmptyProductList-illustBackground"
                alt=""
            >
            <lottie-player
                :path="getAnimationUrl"
                :height="animationHeight"
                :width="animationWidth"
                class="EmptyProductList-illustAnimatedElement"
            />
        </div>
    </div>
</template>

<script setup>
import {
    computed,
    ref
} from 'vue'
import LottiePlayer from 'vue-lottie-player'
import config from 'config'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'

const { t } = useI18n()
const store = useStore()

const animationHeight = ref('70%')
const animationWidth = ref('auto')

const getAnimationBackground = computed(() => `${process.env.VUE_APP_CDN_URL}img/default/empty-lists-illustrations/empty-list-bg.svg`)
const getAnimationUrl = computed(() => `${process.env.VUE_APP_CDN_URL}img/default/empty-lists-illustrations/empty-product-list-animation.json`)
const getCatalogAppliedFilters = computed(() => store.state.catalogModule.appliedFilters)
const getLink = computed(() => {
    if (hasLink.value) {
        return `${config.cms_url}${t('catalog:empty_product_list_with_link:link_url')}`
    }

    return ''
})
const getLinkText = computed(() => {
    if (hasLink.value) {
        return t('catalog:empty_product_list_with_link:link_text')
    }

    return ''
})
const getMessageKey = computed(() => {
    if (getCatalogAppliedFilters.value.length > 0) {
        return 'catalog:filter:empty_product_list'
    }

    return hasLink.value
        ? 'catalog:empty_product_list_with_link:message'
        : 'catalog:empty_product_list'
})
const hasLink = computed(() => getCatalogAppliedFilters.value.length === 0 && (config.cms_url ?? false))
</script>

<style lang="scss" scoped>
@import 'globalScss';

.EmptyProductList {
    padding: 0 $s4;

    &-message {
        @extend %h2;

        margin-bottom: 50px;

        // Filter used on phone / tablet
        padding-right: calc(48px + $s4 * 2);
    }

    &-illustWrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }

    &-illustBackground {
        width: 90%;
    }

    &-illustAnimatedElement {
        position: absolute;
        top: 50%;
        right: 0;
        left: 0;
        margin-top: -20%;
    }
}
</style>
