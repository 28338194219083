import cachios from 'cachios'
import config from 'config'
import { isEmpty } from 'global-utils'
import {
    getKasConfigurationSettings,
    getRawUserInfos,
    getUserTokenAccessToken,
    normalizeUserInfos
} from '@/StoreWeb/js/composables/login-utils'
import store from '@/StoreWeb/store/store'
import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import * as userMutationTypes from '@/StoreWeb/store/modules/user/mutation-types'
import AccountAssociationManager from '@/StoreWeb/managers/AccountAssociationManagerSingleton'
import AddressManager from '@/StoreWeb/managers/AddressManagerSingleton'
import AddToCartFlowManager from '@/StoreWeb/managers/AddToCartFlowManagerSingleton'
import ApplicationSessionManager from '@/StoreWeb/managers/ApplicationSessionManagerSingleton'
import CardReaderManager from '@/StoreWeb/managers/CardReaderManagerSingleton'
import CartManager from '@/StoreWeb/managers/CartManagerSingleton'
import CatalogManager from '@/StoreWeb/managers/CatalogManagerSingleton'
import DebuggingManager from '@/StoreWeb/managers/DebuggingManagerSingleton'
import DocumentManager from '@/StoreWeb/managers/DocumentManagerSingleton'
import GlobalManager from '@/StoreWeb/managers/GlobalManagerSingleton'
import LoginManager from 'user/Login/managers/LoginManager'
import * as loginMutationTypes from 'user/Login/store/mutation-types'
import NavigationGuardManager from '@/StoreWeb/managers/NavigationGuardManagerSingleton'
import PageConfigManager from '@/StoreWeb/managers/PageConfigManagerSingleton'
import PaymentManager from '@/StoreWeb/managers/PaymentManagerSingleton'
import UserManager from '@/StoreWeb/managers/UserManagerSingleton'
import CartSerializer from '@/StoreWeb/serializers/CartSerializerSingleton'
import AnonymousManager from '@/StoreWeb/managers/AnonymousManagerSingleton'
import Cookies from 'js-cookie'

const init = () => {
    const userToken = Cookies.get('userToken')
    const isAuthenticated = !isEmpty(userToken)

    cachios.axiosInstance.defaults.baseURL = process.env.VUE_APP_API_URL
    cachios.axiosInstance.defaults.headers.common = {
        Accept: 'application/json',
        'X-API-Key': process.env.VUE_APP_API_KEY
    }
    let isCheckAccountEnabled = localStorage.getItem('isCheckAccountEnabled')
    if (!isEmpty(isCheckAccountEnabled)) {
        isCheckAccountEnabled = parseInt(isCheckAccountEnabled) === 1
    } else {
        isCheckAccountEnabled = config.userweb.check_account
    }
    store.commit(mainMutationTypes.SET_IS_CHECK_ACCOUNT_ENABLED, isCheckAccountEnabled)

    if (isAuthenticated) {
        store.commit(userMutationTypes.SET_IS_AUTHENTICATED, true)
        store.commit(`login/${loginMutationTypes.SET_AUTHENTICATED}`, true)
    }

    if (!config.web_harmony.header) {
        LoginManager.checkAccount = isCheckAccountEnabled ?? false
        LoginManager.cookiesDomain = config.userweb.cookies_domain ?? location.hostname
        LoginManager.additionalInfoDomain = config.userweb.additional_info_cookies_domain ?? LoginManager.cookiesDomain
        LoginManager.isAdditionalInfoRequired = config.userweb.is_additional_info_required ?? true
        LoginManager.redirectUrl = `${window.location.origin}/signedin`
        LoginManager.store = store
        LoginManager.userWebUrl = process.env.VUE_APP_USER_URL
        LoginManager.userInfosAttributes = config.userweb.kas_user_infos_fields
        LoginManager.init()
    } else {
        const kasConfigurationSettings = getKasConfigurationSettings()

        if (kasConfigurationSettings) {
            store.commit(userMutationTypes.SET_KAS_CONFIGURATION_SETTINGS, kasConfigurationSettings)
        }

        if (isAuthenticated) {
            const accessToken = getUserTokenAccessToken()

            if (accessToken) {
                cachios.axiosInstance.defaults.headers.common.Authorization = accessToken
            }

            const rawUserInfos = getRawUserInfos()

            if (rawUserInfos) {
                const userInfos = normalizeUserInfos(rawUserInfos)

                store.commit(userMutationTypes.SET_RAW_USER_INFOS, rawUserInfos)
                store.commit(userMutationTypes.SET_USER_INFOS, userInfos)
            }
        }
    }

    const accountAssociationManager = AccountAssociationManager.getInstance()
    accountAssociationManager.store = store

    const addressManager = AddressManager.getInstance()
    addressManager.store = store

    const addToCartFlowManager = AddToCartFlowManager.getInstance()
    addToCartFlowManager.store = store

    const anonymousManager = AnonymousManager.getInstance()
    anonymousManager.store = store

    const applicationSessionManager = ApplicationSessionManager.getInstance()
    applicationSessionManager.store = store

    const cardReaderManager = CardReaderManager.getInstance()
    cardReaderManager.store = store

    const cartManager = CartManager.getInstance()
    cartManager.store = store

    const catalogManager = CatalogManager.getInstance()
    catalogManager.store = store

    const debuggingManager = DebuggingManager.getInstance()
    debuggingManager.store = store
    debuggingManager.init()

    const documentManager = DocumentManager.getInstance()
    documentManager.store = store

    const globalManager = GlobalManager.getInstance()
    globalManager.store = store

    const navigationGuardManager = NavigationGuardManager.getInstance()
    navigationGuardManager.store = store

    const pageConfigManager = PageConfigManager.getInstance()
    pageConfigManager.store = store

    const paymentManager = PaymentManager.getInstance()
    paymentManager.store = store

    const userManager = UserManager.getInstance()
    userManager.store = store

    const cartSerializer = CartSerializer.getInstance()
    cartSerializer.store = store
}

export default {
    init
}
