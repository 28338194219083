<template>
    <main class="Home">
        <template v-if="config.header.template === 'sytral'">
            <fare-media-context-bar
                v-if="isAuthenticated"
                class="Home-productContextBar"
                @switch-fare-media-context="setShowFareMediaChoiceModal"
            />
            <product-filter-bar
                v-if="getCatalogFilters.length && mdAndUp"
                class="Home-productFilterBar"
            />
        </template>
        <v-container
            v-if="!isWalletLoading"
            :class="`Home-container--${config.header.template}`"
            class="Home-container"
        >
            <v-row
                :no-gutters="config.header.template === 'sytral' ? smAndDown : xsOnly"
                class="Home-content"
            >
                <v-col
                    v-if="config.header.template === 'default' && lgAndUp"
                    class="Home-sidebar pt-0 pr-4"
                    lg="3"
                >
                    <fare-media-selector
                        v-if="needFareMediaSelector"
                        @switch-fare-media-context="setShowFareMediaChoiceModal"
                    />
                    <product-filter-list
                        v-if="getCatalogFilters.length > 0"
                        :shouldShowFiltersSidebar="true"
                        class="Home-sidebarFilters"
                    />
                </v-col>
                <v-col
                    :lg="config.header.template === 'sytral' ? 12 : lgAndUp ? 9 : 12"
                    class="Home-main pt-0 pl-lg-6 pl-xl-10"
                >
                    <catalog-top-empty-provider-account-banner
                        v-if="needEmptyProviderBanner"
                        :maxWidthWrapper="'100%'"
                        :providers="getWalletProviders"
                        class="Home-banner"
                        @open-card-reader="openCardReader()"
                        @redirect-to-card="redirectToCard()"
                        @show-subscription-modal="setShowSubscriptionModal(getWalletProviders)"
                    />
                    <debug-application-session v-if="debugMode && debugInformationsDisplay > 2" />
                    <product-list-placeholders v-if="getShowProductListLoader" />
                    <product-list
                        v-for="section in getProductsPerSection"
                        v-else-if="!getShowEmptyProductList"
                        v-bind="section"
                        :key="section.id"
                    />
                    <empty-product-list v-else />
                    <v-btn
                        v-if="showStickFilterButton && !getShowProductListLoader"
                        class="Home-stickyFilterButton"
                        color="primary"
                        @click="showCatalogFiltersModal"
                    >
                        <icomoon-icon
                            :layersCount="0"
                            :name="'Filter'"
                            class="Home-stickyFilterButtonIcon"
                        />
                        <span v-if="mdAndUp">{{ $t('button:filter') }}</span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-container>
        <modal
            v-if="mdAndDown"
            v-model="isCatalogFiltersModalShown"
            :title="$t('catalog:filter:modal_title')"
            :width="'full'"
        >
            <template v-if="!config.web_harmony.header">
                <product-filter-select-list
                    class="ProductFilterBar-filters"
                />
            </template>
            <template v-else>
                <fare-media-selector
                    v-if="needFareMediaSelector"
                    class="Home-sidebarFareMediaSelector"
                    @switch-fare-media-context="setShowFareMediaChoiceModal"
                />
                <product-filter-list
                    v-if="getCatalogFilters.length > 0"
                    :shouldShowFiltersSidebar="true"
                    class="Home-sidebarFilters"
                    @close-modal="closeCatalogFiltersModal"
                />
            </template>
            <template #actions>
                <v-btn
                    class="bg-primary"
                    size="large"
                    @click="applyFilters"
                >
                    {{ t('button:filter') }}
                </v-btn>
            </template>
        </modal>
        <product-modal :context="addToCartContext()" />
        <product-vanish-modal />
        <fare-media-choice-modal
            v-model="showFareMediaChoiceModal"
            @go-next-action="fareMediaChoiceModalAction"
            @close-modal="closeAllFareMediaChoiceOrAddModals"
        />
        <fare-media-add-modal
            v-model="showFareMediaAddModal"
            @go-next-action="fareMediaAddModalCloseAction"
            @close-modal="closeAllFareMediaChoiceOrAddModals"
        />
        <fare-media-add-new-modal
            v-model="showFareMediaAddNewModal"
            @go-next-action="fareMediaAddNewModalCloseAction"
            @close-modal="closeAllFareMediaChoiceOrAddModals"
        />
        <fare-media-add-existant-modal
            v-model="showFareMediaAddExistantModal"
            @go-next-action="fareMediaAddExistantModalCloseAction"
            @close-modal="closeAllFareMediaChoiceOrAddModals"
        />
        <user-account-subscription-modal v-model="showUserAccountSubscriptionModal" />
        <user-account-third-party-subscription-modal v-model="showUserAccountThirdPartyModal" />
        <v-btn
            v-if="showBottomFixedFilterButton"
            class="Home-filtersButton"
            color="primary"
            @click="showCatalogFiltersModal"
        >
            <icomoon-icon
                :layersCount="0"
                :name="'Filter'"
                class="Home-filtersButtonIcon"
            />
            {{ $t('button:filter') }}
        </v-btn>
        <logout-confirmation-modal v-model="showControlModal" />
    </main>
</template>

<script setup>
import {
    computed,
    onBeforeMount,
    onMounted,
    onUnmounted,
    ref,
    watch
} from 'vue'
import * as catalogActionTypes from '@/StoreWeb/store/modules/catalog/action-types'
import * as mainMutationTypes from '@/StoreWeb/store/modules/main/mutation-types'
import emitter from 'global-emitter'
import ProductFilterSelectList from '@/StoreWeb/components/product/ProductFilterSelectList'
import ProductFilterList from '@/StoreWeb/components/product/ProductFilterList'
import { useStore } from 'vuex'
import config from 'config'
import { useRoute, useRouter } from 'vue-router'
import FareMediaContextBar from '@/StoreWeb/components/fare-media/FareMediaContextBar.vue'
import ProductFilterBar from '@/StoreWeb/components/product/ProductFilterBar.vue'
import EmptyProductList from '@/StoreWeb/components/common/empty-list-animations/EmptyProductList.vue'
import ProductModal from '@/StoreWeb/components/product/ProductModal.vue'
import ProductVanishModal from '@/StoreWeb/components/product/ProductVanishModal.vue'
import FareMediaChoiceModal from '@/StoreWeb/components/fare-media/FareMediaChoiceModal.vue'
import ProductList from '@/StoreWeb/components/product/ProductList.vue'
import ProductListPlaceholders from '@/StoreWeb/components/product/ProductListPlaceholders.vue'
import FareMediaAddModal from '@/StoreWeb/components/fare-media/FareMediaAddModal.vue'
import FareMediaAddNewModal from '@/StoreWeb/components/fare-media/FareMediaAddNewModal.vue'
import FareMediaAddExistantModal from '@/StoreWeb/components/fare-media/FareMediaAddExistantModal.vue'
import IcomoonIcon from '@/StoreWeb/components/common/IcomoonIcon.vue'
import { isEmpty } from 'global-utils'
import DebugApplicationSession from '@/StoreWeb/components/common/debug/DebugApplicationSession'
import { useI18n } from 'vue-i18n'
import UserAccountThirdPartySubscriptionModal from '@/StoreWeb/components/account/UserAccountThirdPartySubscriptionModal.vue'
import UserAccountSubscriptionModal from '@/StoreWeb/components/account/UserAccountSubscriptionModal.vue'
import FareMediaSelector from '../components/fare-media/FareMediaSelector.vue'
import {
    autoSetSelectedFareMedia,
    getSelectedFareMediaCookie,
    removeSelectedFareMediaCookie,
    setSelectedFareMediaCookie
} from '@/StoreWeb/js/mixins/wallet-utils'
import { addToCartContext, checkAndGetFareMedia } from '@/StoreWeb/js/mixins/faremedia-utils'
import { useDisplay } from 'vuetify'
import { useModalActions } from '@/StoreWeb/js/composables/add-faremedia-modals-utils'
import { getIsAuthenticated, useLogin } from '@/StoreWeb/js/composables/login-utils'
import * as userMutationTypes from '@/StoreWeb/store/modules/user/mutation-types'
import { isSelectedSupportBelongsToCart } from '@/StoreWeb/js/composables/cart-utils'
import { useCatalogUrlFilters } from '@/StoreWeb/js/composables/catalog-filters'
import * as userActionTypes from '@/StoreWeb/store/modules/user/action-types'
import LogoutConfirmationModal from '@/StoreWeb/views/LogoutConfirmationModal.vue'

const { updateFilters } = useCatalogUrlFilters()
const store = useStore()
const { t } = useI18n()
const { xsOnly, smAndDown, mdAndUp, mdAndDown, lgAndUp } = useDisplay()
const route = useRoute()
const router = useRouter()
const {
    closeAllFareMediaChoiceOrAddModals,
    fareMediaAddExistantModalCloseAction,
    fareMediaAddModalCloseAction,
    fareMediaAddNewModalCloseAction,
    fareMediaChoiceModalAction,
    setShowFareMediaChoiceModal,
    showFareMediaAddExistantModal,
    showFareMediaAddModal,
    showFareMediaAddNewModal,
    showFareMediaChoiceModal
} = useModalActions()
const { isAuthenticated } = useLogin()

const isCatalogFiltersModalShown = ref(false)
const showUserAccountSubscriptionModal = ref(false)
const showUserAccountThirdPartyModal = ref(false)
const showControlModal = ref(false)

const cart = computed(() => store.state.cartModule.cart)
const wallet = computed(() => store.state.userModule.wallet)
const getShowEmptyProductList = computed(() => getProductsPerSection.value.length === 0 && !showGlobalLoader.value.value)
const getShowProductListLoader = computed(() => catalogPendingRequests.value.getCatalog)
const currentBreakpointName = computed(() => currentBreakpointName)
const isWalletLoading = computed(() => store.state.userModule.userPendingRequests.getWallet)
const getProductsPerSection = computed(() => store.getters.getProductsPerSection)
const catalogPendingRequests = computed(() => store.state.catalogModule.catalogPendingRequests)
const debugInformationsDisplay = computed(() => store.state.debuggingModule.debugInformationsDisplay)
const debugMode = computed(() => store.state.debuggingModule.debugMode)
const getCatalogFilters = computed(() => store.state.catalogModule.filters)
const selectedFareMedia = computed(() => store.state.userModule.selectedFareMedia)
const showGlobalLoader = computed(() => store.state.showGlobalLoader)
const getWalletProviders = computed(() => store.getters.getWalletProviders)
const getWalletAllSupports = computed(() => store.getters.getWalletAllSupports)
const getCartFareMedias = computed(() => store.getters.getCartFareMedias)
const fareMedias = computed(() => store.getters.getWalletFareMedias)
const needFareMediaSelector = computed(() => config.features.catalog.fare_media_selector && !needEmptyProviderBanner.value)

const needEmptyProviderBanner = computed(() => {
    const mainProvider = getWalletProviders.value?.[0]
    const providerConfig = config.providers.find(provider => provider.id === mainProvider?.id)

    if (!mainProvider || !providerConfig.empty_provider_banner) return false

    // Accounts and FareMedias case
    if (providerConfig.empty_provider_banner.use_accounts && providerConfig.empty_provider_banner.use_fare_medias) {
        // The user has a MaaS account but without provider one
        const maasOnly = !mainProvider.accounts || mainProvider.accounts?.length === 0
        // The user has a MaaS account, a provider one but he didn't link a fareMedia
        const maasAndProviderOnly = mainProvider.accounts?.length >= 1 && (!mainProvider.accounts[0].fareMedias || mainProvider.accounts[0].fareMedias.length === 0)

        return maasOnly || maasAndProviderOnly
    }

    // Default case
    return false
})

const showBottomFixedFilterButton = computed(() => !config.web_harmony.header && mdAndDown.value)
const showStickFilterButton = computed(() => config.web_harmony.header && mdAndDown.value)

// When the route changes and we already are on the catalog page, we need to update the selected fare media
// if a fareMediaId is specified in the route params
watch(route, () => {
    // When the fareMedia is selected from the FareMediaChoiceModal, we don't want to refresh the fare media
    // even if the route has changed
    if (route.query?.needsRefresh === 'false') {
        return
    }

    if (!route.params?.fareMediaId) {
        return
    }

    selectRouteFareMedia()
})

watch(() => wallet.value, () => {
    if (wallet.value === null || !isEmpty(wallet.value?.lastSelectedFareMediaId)) {
        if (isEmpty(selectedFareMedia.value) && isEmpty(route.params?.fareMediaId)) {
            autoSetSelectedFareMedia()
        } else if (route.params?.fareMediaId) {
            selectRouteFareMedia()
        } else {
            refreshCatalog()
        }
    } else {
        const selectedFareMediaCookie = getSelectedFareMediaCookie()

        if (!isEmpty(selectedFareMediaCookie) && selectedFareMediaCookie.fareMediaId !== 'ALL') {
            autoSetSelectedFareMedia()
        } else {
            refreshCatalog()
        }
    }
})

function applyFilters () {
    updateFilters()
    isCatalogFiltersModalShown.value = false
}

function selectRouteFareMedia () {
    let correspondingWalletSelectedSupport = getWalletAllSupports.value.find(supportMedia => supportMedia.id === route.params?.fareMediaId)
    if (isEmpty(correspondingWalletSelectedSupport) && !isEmpty(getCartFareMedias.value)) {
        correspondingWalletSelectedSupport = getCartFareMedias.value.find(supportMedia => supportMedia.id === route.params?.fareMediaId)
    }

    let isTemporary = false

    if (!isEmpty(correspondingWalletSelectedSupport)) {
        isTemporary = isSelectedSupportBelongsToCart(correspondingWalletSelectedSupport, cart.value)
    }

    const selectedSupport = {
        fareMediaId: route.params?.fareMediaId,
        isTemporary,
        providerId: route.params?.providerId,
        providerUserExternalId: route.params?.providerUserExternalId,
        providerUserId: route.params?.providerUserId
    }

    setSelectedFareMediaCookie(selectedSupport)

    store.commit(userMutationTypes.SET_SELECTED_SUPPORT, (!isEmpty(correspondingWalletSelectedSupport) && correspondingWalletSelectedSupport) || null)

    emitter.emit('selectedSupportChanged')
}

function showCatalogFiltersModal () {
    isCatalogFiltersModalShown.value = true
}

async function refreshCatalog () {
    await store.dispatch(catalogActionTypes.GET_CATALOG)
}

function setShowUserAccountSubscriptionModal () {
    showUserAccountSubscriptionModal.value = true
}

function setShowUserSubscriptionThirdPartyModal () {
    showUserAccountThirdPartyModal.value = true
}

function setShowSubscriptionModal (walletProvider) {
    const provider = getWalletProviders.value.find(provider => provider.id === walletProvider[0].id)
    if (provider.walletCreateAccount && provider.walletCreateAccount.mode === 'SYTRALSOUSCRIPTION') {
        setShowUserAccountSubscriptionModal()
    } else if (provider.walletCreateThirdPartyAccount && provider.walletCreateAccount.mode === 'SYTRALTHIRDPARTYSOUSCRIPTION') {
        setShowUserSubscriptionThirdPartyModal()
    } else {
        emitter.emit('showAddFareMediaModal', { walletId: provider.id, formType: provider.walletAddAccount.mode })
    }
}

function redirectToCard () {
    router.push({ name: 'product', params: { id: t('link_to_card:id'), slug: t('link_to_card:slug') } })
}

const checkAndApplyFareMedia = async (support) => {
    store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { title: t('default_loading:title'), subtitle: t('default_loading:subtitle'), value: true })
    const params = {
        providerId: support.providerId,
        fareMediaId: support.fareMediaId
    }
    if (!isEmpty(support.providerUserId)) {
        params.accountId = support.providerUserId
    }
    if (support?.providerUserExternalId) {
        params.providerUserExternalId = support.providerUserExternalId
    }
    if (support?.isCadMode) {
        params.isCadMode = true
    }
    const response = await checkAndGetFareMedia(params)
    store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { value: false })

    if (response.isValid && response?.fareMedia?.id) {
        let fareMedia = response?.fareMedia

        if (isAuthenticated.value && fareMedias.value.length > 0) {
            const walletFareMedia = fareMedias.value.find(walletFareMedia => walletFareMedia.id === fareMedia.id)

            if (!isEmpty(walletFareMedia)) {
                fareMedia = walletFareMedia
            }
        }
        store.commit(userMutationTypes.SET_SELECTED_SUPPORT, fareMedia)
        emitter.emit('selectedSupportChanged')
    } else {
        store.commit(userMutationTypes.SET_SELECTED_SUPPORT, null)
        removeSelectedFareMediaCookie()
        emitter.emit('selectedSupportChanged')
    }
}

async function getWallet () {
    if (!getIsAuthenticated()) {
        return
    }
    store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, {
        keepShow: true,
        title: t('default_loading:title'),
        subtitle: t('default_loading:subtitle'),
        value: true
    })
    const wallet = await store.dispatch(userActionTypes.GET_WALLET, {
        wsOptions: {
            cacheAsync: false,
            cacheValidity: 0
        }
    }).finally(() => {
        store.commit(mainMutationTypes.SET_SHOW_GLOBAL_LOADER, { keepShow: false, resetTexts: true, value: false })
    })
    if (config.features.restore_last_fare_media && wallet) {
        autoSetSelectedFareMedia()
    }
}

function openCardReader () {
    showControlModal.value = true
}

onBeforeMount(async () => {
    await getWallet()
})

onMounted(async () => {
    emitter.on('addToCartSuccess', refreshCatalog)
    emitter.on('cartDeleted', refreshCatalog)

    if (store.getters.getWallet?.providers?.length === 0) {
        await getWallet()
    }

    // Set fare media from the url params
    if (route.params?.fareMediaId && route.params?.providerId && route.params?.providerUserExternalId) {
        return selectRouteFareMedia()
    }

    // Check into the cookie if we saved a fare media
    if (!selectedFareMedia.value) {
        const selectedFareMediaCookie = getSelectedFareMediaCookie()

        if (!isEmpty(selectedFareMediaCookie) && selectedFareMediaCookie.fareMediaId !== 'ALL') {
            return checkAndApplyFareMedia(selectedFareMediaCookie)
        }
    }

    // Get standard catalog because we didn't find a faremedia neither into the route or cookie
    if ((!isAuthenticated.value || (isAuthenticated.value && !isEmpty(wallet.value))) && !getShowProductListLoader.value && isEmpty(getProductsPerSection.value)) {
        await refreshCatalog()
    }
})

onUnmounted(() => {
    emitter.off('addToCartSuccess', refreshCatalog)
    emitter.off('cartDeleted', refreshCatalog)
})
</script>

<style lang='scss' scoped>
@import 'globalScss';

.Home {
    background: $color-lightest;

    &-container {
        max-width: 100%;
        margin: 0 auto;
        padding: $s7 0;

        @media #{map-get($display-breakpoints, 'md-and-up')} {
            padding: $s10 $s4;
        }

        @media #{map-get($display-breakpoints, 'lg-and-up')} {
            max-width: 1614px;
            padding-top: $s14 $s4;
        }

        &--sytral {
            max-width: 1095px;
        }
    }

    &-banner {
        margin-bottom: $s8;
    }

    &-content {
        margin-top: 0;
        margin-bottom: 0;
    }

    &-sidebar {
        border-right: 1px solid $color-lightgray4;
    }

    &-sidebarFareMediaSelector {
        max-width: 324px;
    }

    &-stickyFilterButton {
        position: fixed;
        z-index: 1;
        top: 93px;
        right: $s4;
        align-self: flex-end;
        width: $s12 !important;
        min-width: auto !important;
        height: $s12 !important;
        margin: 0 !important;
        border-radius: $borderRadius-rounded !important;

        @media #{map-get($display-breakpoints, 'md-and-up')} {
            top: 105px;
            right: $s8;
            width: auto !important;
            min-width: $s16 !important;
            border-radius: $s3 !important;
        }

        @media #{map-get($display-breakpoints, 'lg-and-up')} {
            top: 121px;
        }
    }

    &-filtersButton {
        position: fixed;
        z-index: 1;
        bottom: 30px;
        left: 50%;
        width: 110px;
        margin-left: -55px;
    }

    &-filtersButtonIcon,
    &-stickyFilterButton {
        font-size: $font-medium;

        @media #{map-get($display-breakpoints, 'md-and-up')} {
            margin-right: $s1;
        }
    }

    &-filtersButtonIcon {
        margin-right: $s1;
    }
}
</style>
